window.additionalYMarginRatio = 0.03;
window['canvasInstances'] = [];
window['cropper'] = undefined;
window['typing_form'] = undefined;
window['button_scroller'] = undefined;
window['notes_modal'] = undefined;
window['images_preloader'] = undefined;
window['preview_modal'] = undefined
window['isEnabledDisplayOnlyBookmarkedItems'] = false
window['onscreen_keyboard'] = undefined;
window['spelling_sources'] = undefined;
window['isLatinHistoryModalOpen'] = false
window['isContinuingColumnsModalOpen'] = false
window['pageset_approve_or_return_modal'] = undefined
window['submit_page_set_modal'] = undefined
window['typeo_prepopulation'] = undefined