import ApplicationController from "./application_controller"
import Cookies from 'js-cookie';

export default class extends ApplicationController {

  static targets = [
    'onscreenKeyboard'
  ];

  initialize() {
    window['onscreen_keyboard'] = this;
    this.#initializeDynamicValues()
    this.addListnerToOnscreenKeyboardButtons();
    this.dragKeyboard();
    this.handleOnscreenKeyboardVisibility();
  }

  #initializeDynamicValues() {
    this.stage = this.element.dataset.stage;
  }

  handleOnscreenKeyboardVisibility(){
    const keyboardVisibilityCookie = `onscreen_keyboard_for_${this.stage}_stage`;

    // Read the visibility status from the cookie
    const keyboardVisible = Cookies.get(keyboardVisibilityCookie) === 'true';
    const onscreenKeyboard = document.querySelector('[data-onscreen-keyboard-target="onscreenKeyboard"]');

    if (onscreenKeyboard) {
      if (keyboardVisible) {
        onscreenKeyboard.classList.remove('collapse');
      } else {
        onscreenKeyboard.classList.add('collapse');
      }
    }
  }

  isTypeoStage() {
    return this.stage === 'type_o'
  }

  toggleOnscreenKeyboardVisibility(e) {
    const keyboardVisibilityCookie = `onscreen_keyboard_for_${this.stage}_stage`;
    this.onscreenKeyboardTarget.classList.toggle("collapse");
    const isVisible = !this.onscreenKeyboardTarget.classList.contains("collapse");
    Cookies.set(keyboardVisibilityCookie, isVisible);
  }

  addListnerToOnscreenKeyboardButtons() {
    const buttons = document.querySelectorAll(".js-onscreen-keyboard-btns");
    let action = "undefined";
    // if (this.isTypeoStage()) {
    action = "click->typing-form#addSpecialCharacterForTypingForm";
    // }

    buttons.forEach(button => {
      button.setAttribute("data-action", action);
    });
  }

  dragKeyboard() {
    dragKeyboardElement(this.onscreenKeyboardTarget);
  }
}

const dragKeyboardElement = (elmnt) => {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

  var saveMouseUp = null;
  var saveMouseMove = null;

  if (elmnt) {
    const lastPosition = localStorage.getItem("keyboardLastPositionOnScreen");
    if (lastPosition) {
      const { top, left } = JSON.parse(lastPosition);
      elmnt.style.top = top;
      elmnt.style.left = left;
    }

    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    saveMouseUp = document.onmouseup;
    saveMouseMove = document.onmousemove;

    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = saveMouseUp;
    document.onmousemove = saveMouseMove;

    // Save the current position to localStorage
    localStorage.setItem("keyboardLastPositionOnScreen", JSON.stringify({
      top: elmnt.style.top,
      left: elmnt.style.left
    }));     
  }
}
