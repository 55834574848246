import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['advanceFilterButton', 'advanceFilterContainer']

  initialize(){
  }

  connect(){
    this.element.addEventListener("input", this.handleInput.bind(this));
  }

  handleInput(event) {
    const searchTextField = event.target;
    const searchValue     = searchTextField.value;

    if (searchValue.match(/[\u0600-\u06FF]/)) { // Arabic characters
      searchTextField.classList.add("arabic-text-global-search");
      searchTextField.classList.remove("english-text-global-search");
    } else {
      searchTextField.classList.add("english-text-global-search");
      searchTextField.classList.remove("arabic-text-global-search");
    }
  }

  toggleAdvanceFilters(){
    this.advanceFilterContainerTarget.classList.toggle('collapse')
  }
}
